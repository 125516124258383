@use "../../config/styleVariable.scss" as global;
$borderRadius: 12px;

.storehome-category {
  display: flex;
  border-radius: 8px;
  padding-block: 4px;
  color: black;

  &:hover {
    color: global.$primary;
  }
  &.active {
    color: global.$primary;
    border-bottom: 2px solid global.$primary;
  }
}
.storehome-header {
  background-color: global.$backround;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 1000;
  padding-left: 30px;
  padding-right: 30px;
  transition: 0.3s;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-left: 10px;
  }
}
.storehome-main {
  padding-left: 40px !important;
  padding-right: 40px !important;
  margin-top: 30px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.storehome {
  background-color: rgb(247 247 247);
}
.storehome-header-menu-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
}
.storehome-header-phone {
  font-size: 16px;
  font-family: global.$SemiBold;
  @media screen and (min-width: 320px) and (max-width: 880px) {
    margin-right: 10px;
  }
}
.storehome-category-menu {
  border-bottom: 0px;
  font-size: 16px;
  font-family: global.$SemiBold;
  width: 100%;
  display: flex !important;
  justify-content: flex-end;
}
.storehome-store-details {
  font-size: 22px;
  font-family: global.$Bold;
  min-width: fit-content;
  display: flex;
  align-items: center;
  margin-left: 20px;
  &:hover {
    cursor: pointer;
  }
  @media screen and (min-width: 320px) and (max-width: 720px) {
    margin-left: 0px;
  }

}
.storehome-firstsection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.storehome-firstsection-text {
  font-size: 16px;
  font-family: global.$SemiBold;
}
.storehome-firstsection-icon {
  margin-left: 20px;
  font-size: 16px;
  font-family: global.$SemiBold;
  @media screen and (min-width: 320px) and (max-width: 880px) {
    margin-left: 5px;
  }
  &:last-child {
    margin-right: 1rem;
  }
}
.storehome-categories {
  position: relative;
}
.storehome-categories-card-header {
  font-family: global.$SemiBold;
  font-size: 20px;
}
.storehome-categories-card-body {
  font-family: global.$SemiBold;
  font-size: 14px;
  overflow-x: scroll;
  padding: 15px 0;
  scroll-behavior: smooth;
  //scrollbar-width: none;
  white-space: nowrap;
  display: flex;
  gap: 1.5rem;
  padding-left: 0.5rem;
  justify-content: flex-start;
  @media (min-width: 768px) {
    gap: 2rem;
    padding-left: 0;
    margin-bottom: 1rem;
    // margin-top: 1rem;
  }
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
.store-categories-single-img {
  $size: 90px;
  width: $size;
  height: $size;
  border-radius: 100%;
  padding: 2px;
  cursor: pointer;
  &.active {
    border: 1px solid global.$primary;
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
  }
  @media (max-width: 620px) {
    width: 70px;
    height: 70px;
    padding: 3px;
  }
}
.store-categories-single-title {
  cursor: pointer;
  &.active {
    color: global.$primary;
  }
}
.store-categories-container {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  align-items: center;
  flex-direction: column;
  display: flex;
}
.store-banner-card-container {
  border-radius: $borderRadius;
}
.store-banner-text-container {
  border-bottom-right-radius: $borderRadius;
  border-bottom-left-radius: $borderRadius;
}
.store-banner-card-title {
  font-size: 14px;
  font-family: global.$Regular;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.store-banner-card-price {
  font-size: 16px;
  font-family: global.$Regular;
}
.store-banner-Horizontal-Pscroll {
  padding-left: 5px;
  margin-bottom: 10px;
  margin-top: 5px;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-left: 10px;
  }
}
.store-banner-Horizontal-Pscroll + .store-banner-Horizontal-Pscroll {
  margin-top: 30px;
}
.store-banner-Horizontal-PscrollBox {
  overflow-x: scroll;
  scroll-behavior: smooth;
  overflow-y: hidden;
  white-space: nowrap;
}

.store-banner-Horizontal-item {
  display: inline-block;
  vertical-align: middle;
  width: 400px;
  margin-right: 16px;
  height: 200px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    width: 300px;
    height: 150px;
  }
}
.store-banner-Horizontal-PscrollBox::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.store-banner-Horizontal-btn1 {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(183, 183, 183);
  color: #000;
  background-color: #fff;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
}
.store-banner-slider-btn-left {
  inset: 35% auto auto -20px;
  // transform: translateY(-100%);
}

.store-banner-slider-btn-right {
  inset: 35% -20px auto auto;
  // transform: translateY(-100%);
}
.store-banner-Horizontal-viewButton {
  font-family: global.$Bold;
  margin-right: 16px;
  font-size: 14px;
  padding-top: 5px;
  color: gray;
  &:hover {
    color: global.$primary;
  }
}
.product-search-multi-title {
  font-size: 20px;
  font-family: global.$SemiBold;
}
.store-banner-item {
  background-color: #3d3838;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 124px;
  &:hover {
    // cursor: pointer;
    box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.1);
  }
  background-color: rgb(253, 253, 253);
  //border: 1px solid rgb(241, 241, 241);
}
.store-banner-item-img {
  border-radius: 20px;
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: scale 100ms;
  // &:hover{
  //   scale: 1.05;
  // }
}
.store-banner-item-box {
  flex: 1;
  overflow: hidden;
}
.store-banner-Horizontal-Heading1 {
  font-family: global.$SemiBold;
  font-size: 20px;
}
.store-banner-Horizontal-title {
  display: flex;
  justify-content: space-between;
}
.store-banner-scroll-btn {
  margin-right: 10px;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  border: 0px;
  &:last-child {
    margin-right: 0px;
  }
}
.storehome-footer {
  margin-top: 2rem;
}
.storehome-header-menu-item {
  font-size: 18px;
  font-family: global.$SemiBold;
  margin-left: 20px;
  &:last-child {
    margin-right: 20px;
  }
}
.storehome-header-logo {
  width: 17rem;
  margin-left: 1rem;
  @media screen and (min-width: 320px) and (max-width: 868px) {
    width: 12rem;
    margin-left: 0rem;
  }
}
