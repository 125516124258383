@use "../../config/styleVariable.scss" as global;
.MenuConfig-sideBox {
  border: 1px solid rgb(234, 234, 234);
  border-top: 0px;
  height: 50vh;
}
.MenuConfig-rowBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}
.MenuConfig-txt1 {
  font-family: global.$SemiBold;
  font-size: 14px;
}
