@use "../../config/styleVariable.scss" as global;
.SuccessModal-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: global.$Medium;
  text-align: center;
}
.SuccessModal-modal-txt1 {
  font-family: global.$Bold;
  font-size: 25px;
}
.SuccessModal-modal-txt2 {
  font-family: global.$Medium;
  color: global.$grey2;
  font-size: 15px;
}
.SuccessModal-image1 {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
