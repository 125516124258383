@use "../../config/styleVariable.scss" as global;
.Contact_text1 {
  font-family: global.$SemiBold;
  font-size: 25px;
  text-align: center;
  margin-bottom: 10px;
}
.Contact_text2 {
  font-family: global.$Medium;
  font-size: 15px;
  color: black;
}
.ContactUs_Box1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}
.icon_box1 {
  display: flex;
  align-items: center;
}
.icon_box2 {
  display: flex;
  gap: 20px;
}
