@use "../../../config/styleVariable.scss" as global;
.wishlist-card-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.profilescreen-address-name-phoneno {
  margin-top: 5px;
  font-size: 18px;
  font-family: global.$SemiBold;
  margin-bottom: 0.5rem;
}
.profilescreen-address-phoneno {
  margin-left: 3rem;
}
.profilescreen-address-fulladdress {
  font-size: 16px;
  font-family: global.$Regular;
}
.profilescreen-address-formaddress {
  font-size: 16px;
  font-family: global.$Regular;
}
.profilescreen-address-editdelete-btn {
  color: global.$primary;
  margin-left: 0.5rem;
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.profilescreen-address-editdelete {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  &:first-child {
    margin-right: 1rem;
  }
}
.profilescreen-address-type {
  background-color: rgb(240, 240, 240);
  padding: 2px 6px;
  border-radius: 4px;
  font-family: global.$Regular;
}
.profilescreen-address-card {
  font-size: 14px;
  font-family: global.$Regular;
  width: 100%;
}
.profilescreen-address-edit-btn {
  font-size: 14px;
  font-family: global.$Regular;
  margin-right: 20px;
  &:hover {
    color: global.$primary;
  }
}
.profilescreen-address-delete-btn {
  font-size: 14px;
  font-family: global.$Regular;
  color: red;
  margin-right: 20px;
  &:hover {
    color: global.$primary;
  }
}
.userSidebar-logo {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 1px solid global.$primary;
  padding: 1px;
  background-color: #dbdbdb;
}
.userSidebar-storeBox {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgb(224, 224, 224);
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
}
.userSidebar-txt1 {
  font-family: global.$SemiBold;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.userSidebar-txt2 {
  font-family: global.$Medium;
  font-size: 12px;
  color: green;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
