@use "../../config/styleVariable.scss" as global;
.Policy_Text1 {
  font-family: global.$Bold;
  font-size: 25px;
  text-align: center;
}
.Policy_Text2 {
  font-family: global.$Medium;
  margin-bottom: 10px;
}
