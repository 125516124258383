.card {
  width: 100%;
  height: 350px;
  cursor: pointer;
  & .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  & .text{
    top: 10px;
    left: 10px;
  }
}
