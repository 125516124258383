body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: "DMSans-Bold";
  src: url("./assets/fonts/DMSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-SemiBold";
  src: url("./assets/fonts/DMSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Medium";
  src: url("./assets/fonts/DMSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Regular";
  src: url("./assets/fonts/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Thin";
  src: url("./assets/fonts/DMSans-Thin.ttf") format("truetype");
}