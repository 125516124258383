@use "../../config/styleVariable.scss" as global;
.signupScreen-txt1 {
  font-family: global.$Bold;
  font-size: 24px;
  text-align: center;
}
.signupScreen-txt2 {
  font-family: global.$Medium;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  color: gray;
}
.signupScreen-box1 {
  padding-left: 50px;
  padding-right: 50px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.signupScreen-txt3 {
  font-family: global.$SemiBold;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    justify-content: flex-end;
    padding-bottom: 30px;
  }
}
.signupScreen-txt4 {
  font-family: global.$SemiBold;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
  color: grey;
}
.signupScreen-txt5 {
  font-family: global.$Bold;
  color: global.$primary;
}
.signupScreen-errortxt {
  font-family: global.$Medium;
  color: red;
  font-size: 16px;
  margin-bottom: 20px;
  margin-bottom: 10px;
}
.SummaryCard {
  border: 1px solid rgb(209, 209, 209);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  font-family: global.$Medium;
  font-size: 14px;
}
