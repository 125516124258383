.banner-table-card-image{
    height: 40px;
    width: 40px;
    object-fit: contain;
    transition: transform 0.3s ease;
}
.banner-table-card-image:hover {
    transform: scale(1.1);
  }
  
.aspect-ratios {
    display: flex;
    align-items: center;
    gap:0.5rem;
    font-size: 1rem;
    font-weight: 500;
  }

.enlarged-image-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .enlarged-image-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1001;
  }
  
  .enlarged-image-content {
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    position: relative;
    z-index: 1002;
  }
  
  .enlarged-image {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
  }
  
  